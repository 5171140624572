* {
  box-sizing: border-box;
  //font-family: -apple-system, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif;
  //font-family: "Segoe UI", "Chinese Quote", Tahoma, SansSerif, sans-serif;
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif !important;
}

html,
#root {
  height: 100%;
}

@font-face {
  font-family: 'text-security-disc';
  src: url('../_fonts/text-security/text-security-disc-compat.eot');
  src: url('../_fonts/text-security/text-security-disc-compat.eot?#iefix') format('embedded-opentype'),
  url('../_fonts/text-security/text-security-disc-compat.woff2') format('woff2'),
  url('../_fonts/text-security/text-security-disc-compat.ttf') format('truetype')
}

@supports ((content-visibility:visible) or (-moz-appearance:meter)) and (not (-webkit-hyphens:none)) {
  @font-face {
    font-family: 'text-security-disc';
    src: url('../_fonts/text-security/text-security-disc.woff2') format('woff2'),
    url('../_fonts/text-security/text-security-disc-compat.ttf') format('truetype')
  }
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: #e1e1e1;
}

a {
  color: inherit;
  text-decoration: none;
}

/*
  Notification
 */
.notification-container-top-right {
  margin-top: 62px;
  margin-right: 20px;
  width: auto;
  .n-parent {
    min-width: 350px !important;
  }
}
.notification-item .notification-content {
  padding: 15px !important;
  max-width: 650px;
  white-space: pre-line;
  max-height: 300px;
  overflow-y: auto;
  cursor: default;
}

.notification-content::-webkit-scrollbar-track {
  background: transparent !important;
}
.notification-content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3) !important;
  border-radius: 2px;
}
.notification-content::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5) !important;
}
.notification-content::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}
.notification-item .notification-close::after {
  font-size: 20px !important;
}

.notification-item .notification-close {
  cursor: pointer;
  width: 30px !important;
  height: 30px !important;
  transform: translate(5px, -5px);
  background-color: transparent;
}

.notification-message .copy-message {
  position: absolute;
  top: 9px;
  right: 30px;
}

.show-on-hover {
  display: none !important;
}
.MuiInputBase-root:hover .show-on-hover {
  display: inherit !important;
}

[class^='MuiInputBase-root']:hover .show-on-hover {
  display: inherit !important;
}

/*
  Scroll
*/

.fullwidth {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.select-none {
  user-select: none !important;
  pointer-events: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

input[placeholder] {
  text-overflow: ellipsis;
}
input::-moz-placeholder {
  text-overflow: ellipsis;
}
input:-moz-placeholder {
  text-overflow: ellipsis;
}
input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.rt-table div {
  min-width: initial !important;
}

.rt-td,
.rt-th {
  width: 0 !important;
}

/*[class*="legendNotched"] {
  max-width: 0.01px !important;
}*/

.grow1 {
  flex-grow: 1;
}
.grow2 {
  flex-grow: 2;
}
.grow3 {
  flex-grow: 3;
}

.fw-2 {
  font-weight: 200;
}
.fw-3 {
  font-weight: 300;
}
.fw-4 {
  font-weight: 400;
}
.fw-5 {
  font-weight: 500;
}
.fw-6 {
  font-weight: 600;
}
.fw-7 {
  font-weight: 700;
}
.fw-8 {
  font-weight: 800;
}

.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-19 {
  font-size: 19px;
}
.fs-20 {
  font-size: 20px;
}
.fs-21 {
  font-size: 21px;
}
.fs-22 {
  font-size: 22px;
}
.fs-23 {
  font-size: 23px;
}
.fs-24 {
  font-size: 24px;
}
.fs-25 {
  font-size: 25px;
}
.fs-26 {
  font-size: 26px;
}
.fs-27 {
  font-size: 27px;
}
.fs-28 {
  font-size: 28px;
}
.fs-29 {
  font-size: 29px;
}
.fs-30 {
  font-size: 30px;
}

.pre-line {
  white-space: pre-line;
}

.contingent .Mui-selected {
  background-color: #fff !important;
}

.contingent_dark .Mui-selected {
  background-color: #252525 !important;
}

.reportСard .rt-td{
  position: relative;
}

.reportBorderBottom {
  padding: 0 !important; 
  margin: 0 !important; 
  border-bottom: 1px solid #f2f2f2;
}
.reportBorderRight {
  padding: 0 !important;
  margin: 0 !important; 
  max-width: 50%;
  width: 50%; 
  border-right: 1px solid #f2f2f2;
}